// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
//require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
require("bootstrap");
require("moment");
require("nouislider");
require("@fortawesome/fontawesome-free");
require('data-confirm-modal');
require('select2');
require('select2/dist/css/select2.css');
require('select2-theme-bootstrap4/dist/select2-bootstrap.min.css');
require('jquery-appear-original');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Font awesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
library.add(fas, faTwitter);
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();

// Template scripts
import './template/core/bootstrap-material-design.min'
import './template/plugins/perfect-scrollbar.jquery.min'
import './template/material-kit'
import './template/material-dashboard'
// Custom javascripts
import './search'
import './offers'
import './location'
import './custom'