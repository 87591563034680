setupAsyncTriggers = function() {
    $('.load-more-btn:not(.linked)').on('click', function () {
        const $btn = $(this);
        const $wrapper = $btn.closest('.load-more-wrapper');
        $btn.addClass('linked');
        const sourceUrl = $btn.data('source');
        loadAsyncContent($wrapper, sourceUrl, true);
    });
    // Load content set from triggers
    $('.load-more-trigger:not(.linked)').each(function() {
        const $trigger = $(this);
        $trigger.addClass('linked');
        const sourceUrl = $trigger.data('source');
        loadAsyncContent($trigger, sourceUrl, true);
    });
    let $loadOnScroll = $('.load-on-scroll:not(.loading)');
    $loadOnScroll.on('appear', function (event, $all_appeared_elements) {
        const $trigger = $(this);
        $trigger.addClass('loading');
        const sourceUrl = $trigger.data('source');
        loadAsyncContent($trigger, sourceUrl, true);
    });
    $loadOnScroll.appear();
};

const LOADING_HTML = '<div class="load-more-spinner text-center w-100"><div class="lds-dual-ring"></div></div>';
loadAsyncContent = function(reference, sourceUrl, includeLoading=true) {
    const $ref = $(reference);
    setVisible($ref, false);
    if (includeLoading) $ref.after(LOADING_HTML);
    const $loadingSpinner = $ref.siblings('.load-more-spinner');
    $.ajax(sourceUrl, {
        dataType: 'html',
        success: function (data, textStatus, jqXHR) {
            $ref.after(data);
            $ref.remove();
            $loadingSpinner.remove();
            setupAsyncTriggers();
        },
        error: function (qXHR, textStatus, errorThrown) {
            setVisible($ref, true);
            $loadingSpinner.remove();
            alert('Se produjo un error al tratar de cargar el contenido: ' + errorThrown);
        }
    });
};

$(function() {
    setupAsyncTriggers()
});