/* Maps */
import Cookies from 'js-cookie/src/js.cookie'

let editMap;
let editMapMarker;

$(document).ready(function() {
    setupLocationToModal();
});

function setupLocationToModal() {
    // Set default location if not exists

    let $modal = $('.modal#location-modal');
    const latLng = { latitude: $modal.data('lat'), longitude: $modal.data('lng') };

    $modal.on('shown.bs.modal', function (e) {
        // Map
        if (!editMap) {
            const mapCanvas = document.getElementById('location-edit-map');
            editMap = new google.maps.Map(mapCanvas, {
                zoom: 15,
                center: new google.maps.LatLng(latLng.latitude, latLng.longitude),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });

            // Create the search box and link it to the UI element.
            const $input = $modal.find('#pac-input');

            const searchBox = new google.maps.places.SearchBox($input[0]);
            editMap.controls[google.maps.ControlPosition.TOP_CENTER].push($input[0]);

            // Animate searchbox opacity
            $input.delay(1000).animate({opacity:1}, 200);

            // Bias the SearchBox results towards current map's viewport.
            editMap.addListener('bounds_changed', function () {
                searchBox.setBounds(editMap.getBounds());
            });

            // [START region_getplaces]
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener('places_changed', function () {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                const latLng = places[0].geometry.location;

                editMapMarker.setPosition(latLng);
                editMap.panTo(latLng);
            });
            // [END region_getplaces]

            const center = new google.maps.LatLng(latLng.latitude, latLng.longitude);
            if (!editMapMarker) {
                editMapMarker = new google.maps.Marker({
                    map: editMap,
                    position: center,
                    draggable: true,
                    title: 'Drag me!'
                });
            } else {
                editMapMarker.setPosition(center);
            }
        }
    });

    // Setup onclick event for accept button
    const $acceptBtn = $('#set-location-btn');
    $acceptBtn.off('click');
    $acceptBtn.on('click', function () {
        let latLong = editMapMarker.getPosition();
        Cookies.set('selected_lat', latLong.lat());
        Cookies.set('selected_lng', latLong.lng());
        Cookies.remove('selected_location_name');
        location.reload();
    });

    const $findLocationBtn = $('#find-location-btn');
    $findLocationBtn.off('click');
    $findLocationBtn.on('click', function () {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            setLoadingLocation(true);
            $findLocationBtn.tooltip('hide');
            navigator.geolocation.getCurrentPosition(function(position) {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                editMapMarker.setPosition(pos);
                editMap.panTo(pos);
                setLoadingLocation(false);
            }, function() {
                setLoadingLocation(false);
                alert('No se dispone de permiso para obtener tu ubicación');
            }, { enableHighAccuracy: false, timeout: 10000});
        } else {
            // Browser doesn't support Geolocation
            alert('Tu navegador no soporta geolocalización');
        }
    });
}

function setLoadingLocation(loading) {
    setVisible('.modal#location-modal .loading-layer', loading);
    $('#set-location-btn, #find-location-btn').prop('disabled', loading);
}