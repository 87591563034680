// Global functions
window.setHidden = function(selector, hidden) {
    const HIDDEN_CLASS = 'd-none';
    const $element = $(selector);
    if (hidden) {
        if (!$element.hasClass(HIDDEN_CLASS)) $element.addClass(HIDDEN_CLASS);
    } else {
        $element.removeClass(HIDDEN_CLASS);
    }
};

window.setVisible = function(selector, visible) {
    setHidden(selector, !visible)
};

setupGlobalSearch = function() {
    const $input = $('#global-search-input');
    const $modal = $('#search-modal');
    $input.on('input', function () {
        onGlobalSearch($input.val());
    });
    $modal.on('shown.bs.modal', function () {
        $input.trigger('focus');
    })
};

// Use a timeout to wait a time for the user to end typing
let searchTimeout = null;
onGlobalSearch = function(query) {
    const $modal = $('#search-modal');
    const $contentDiv = $modal.find('.results-content');
    const $emptySearchDiv = $modal.find('.empty-search');
    // Cancel previous timeout
    if (searchTimeout) clearTimeout(searchTimeout);
    if (query) {
        searchTimeout = setTimeout(function () {
            $.ajax({
                url: '/search',
                data: {q: query},
                dataType: 'html',
                success: function (data, textStatus, jqXHR) {
                    setVisible($emptySearchDiv, false);
                    $contentDiv.html(data);
                    setVisible($contentDiv, true);
                },
                error: function (qXHR, textStatus, errorThrown) {
                }
            })
        }, 200);
    } else {
        setVisible($contentDiv, false);
        setVisible($emptySearchDiv, true);
        $contentDiv.html('');
    }
};


$(function() {
    // Setup global search input
    setupGlobalSearch();
});